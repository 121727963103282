<template>
    <div class="vertical-wrapper pt-lg--10 pt-5 pb-lg--10 pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8 text-center default-page">
                    <div class="card border-0 text-center d-block">
                        <img :src="'/images/payment.svg'" alt="icon" class="w200 mb-4 ml-auto mr-auto ">
                        <h1 class="fw-700 text-success display2-size display2-md-size">You Payment Success!!</h1>
                        <p class="text-grey-500 font-xss">We have verified for your payment! Thank you for working with us.<br>Click button bellow for go to Home page.</p>
                        <router-link to="/" class="p-3 w175 btn-current d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Home</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>    
    export default {
        data() {
            return {
                name: 'Loading',
                email: '',
                verification_at: null
            }
        },
        created(){
            
        },
        methods: {
        }
    }
    </script>
    